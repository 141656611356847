
 // @ is an alias to /src
import Navigation from '@/components/partials/Navigation.vue';
import Aside from '@/components/partials/Aside.vue';
import Footer from '@/components/partials/Footer.vue';
import { defineComponent, ref, reactive, computed } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  name: "Verification",
  components: {
    Navigation,
    Aside,
    Footer,
  },
  setup() {
    const store = useStore();
    const links = reactive([
      {
        name: "De-activation",
        url: "account/verification"
      },
      {
        name: "Re-activation",
        url: "account/verification"
      },
    ]);
    const email = ref("email");
    const name = ref("Account");
    const nav = computed(() => store.getters.nav);

    return { email, name, nav, links };
  },
});
